html, body {
    font-family: 'Heebo', sans-serif;
    width: 100%;
}

h1, h2, h3 {
    color: #160e33;
    letter-spacing: 2px;
}

.mb-100 {
    margin-bottom: 100px;
}

.img-responsive {
    width: 100%;
    height: auto;
}

.img-60 {
    width: 60%;
    height: auto;
}


.btn.btn-theme {
    background: #5AC2F8;
    font-weight: 700;
    color: #fff;
}

.btn.btn-theme:hover {
    background: #49b0e7;
}


/* --- Header --- */
.navbar {
    border-bottom: 1px solid #f0f0f0;
}
.navbar .navbar-brand img {
    height: 50px;
}
.navbar .navbar-toggler i  {
    color: #222;
}
.navbar .nav-item {
    padding-left: 15px;
    padding-right: 15px;
}
.navbar .nav-item.active .nav-link {
    color: #5AC2F8;
    border-bottom: 2px solid #5AC2F8;
}
.navbar .nav-item .nav-link {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222;
    border-bottom: 2px solid transparent;
    -webkit-transition: color 0.2s, border-color 0.3s; /* Safari */
    transition: color 0.2s, border-color 0.3s;
    transition-timing-function: ease-in-out;
}
.navbar .nav-item .nav-link:hover {
    color: #5AC2F8;
    border-bottom: 2px solid #5AC2F8;
}

/* --- Feature --- */
.feature {
    margin-top: 40px;
    margin-bottom: 100px;
}


/* --- Jumbotron --- */
.jumbotron {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    border-radius: 0;
}
.jumbotron.pattern-1 {
    height: 100vh;
    background: #fff;
}
.jumbotron.pattern-2 {
    background-image: url('../img/feature_2_dark.jpg');
    padding-top: 200px;
    height: 80vh;
}
.jumbotron.pattern-3 {
    background-image: url('../img/feature_3_dark.jpg');
    padding-top: 200px;
    height: 80vh;
}
.jumbotron.pattern-4 {
    background-image: url('../img/jumbotron-about.jpg');
    padding-top: 200px;
    height: 80vh;
}
.jumbotron .lead {
    font-size: 30px;
}


/* --- Section --- */
.section {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
}
.section .section-icon {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto 30px auto;
    border: 2px solid #5AC2F8;
    border-radius: 50%;
}
.section .section-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: #5AC2F8;
}
.section .section-title {
    margin-bottom: 120px;
}
.section .section-subtitle {
    margin-bottom: 50px;
}
.section .section-description {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 2px;
    color: #736e84;
    display: block;
}
.section ul {
    list-style-type: none;
    padding: 0;
}
.section ul li {
    font-size: 19px;
    font-weight: 100;
    margin-bottom: 8px;
}

.section.section-cards {
    padding-bottom: 10px !important;
}
.section.section-cards i {
    font-size: 80px;
    color: #49b0e7;
    margin-bottom: 30px;
}
.section.section-cards .card-title {
    font-size: 30px;
}


/* Footer */
.section.section-footer {
    background: #5AC2F8;
    color: #fff;
}
.section.section-footer .nav-link {
    font-size: 18px;
    color: #fff;
}
.section.section-footer .footer-sm {
    font-size: 50px;
    color: #fff;
}

/* Media Queries */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .feature .column-1 {
        margin-bottom: 30px;
    }
    .feature .column-2 {
        text-align: center;
    }
    .feature .column-2 .title {
        font-size: 50px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    
}